import { phoneNumberValidator } from 'common/phoneNumber'
import { Phone, PhoneEdition } from 'common/types'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { Deferred } from 'shared/utils/web/deferred'
import { DataContext } from '../../DataProvider'
import { editPhone } from '../../api'
import { DIALOG_CLOSED_REASON, Dialog } from '../../components/Dialog'
import { FacilitySelector } from '../../components/FacilitySelector'
import { Select } from '../../components/Select'
import { Error, Title } from '../../components/Text'
import { Button, SubmitButton } from '../../components/ui/button'
import { Input } from '../../components/ui/input'
import { Textarea } from '../../components/ui/textarea'
import { PHONE_STATUS, getPhoneStatusDisplay } from '../../utils/phoneStatus'
import { onlyCustomerOrExperimenter } from '../pending/AddPhoneDialog'

export type EditPhoneDialogData = {
  phone: Phone
  firebaseKey: string
  deferred: Deferred<void>
}

export const EditPhoneDialog = ({
  phone,
  firebaseKey,
  deferred,
}: EditPhoneDialogData) => {
  const { facilities, source } = useContext(DataContext)

  const { handleSubmit, register, formState } = useForm<PhoneEdition>({
    defaultValues: {
      status: phone.status,
      comment: phone.comment,
      number: phone.number,
      facilityId: phone.facilityId,
      zoneLocked: phone.zoneLocked ?? false,
      notificationDisabled: phone.notificationDisabled ?? false,
    },
    mode: 'onBlur',
  })

  const handleClose = () => {
    if (!formState.isSubmitting) {
      deferred.reject(DIALOG_CLOSED_REASON)
    }
  }

  return (
    <Dialog title={<Title>Éditer le téléphone</Title>} onClose={handleClose}>
      <form
        className="flex flex-col gap-6"
        onSubmit={handleSubmit(async (values) => {
          await editPhone(firebaseKey, phone, values, source)
          deferred.resolve()
        })}
      >
        <div className="flex flex-col gap-2">
          <label>Établissement</label>
          <FacilitySelector
            facilities={onlyCustomerOrExperimenter(facilities)}
            registration={register('facilityId', { required: true })}
          />
        </div>

        <div className="flex flex-row items-center justify-between gap-4">
          <label>Statut</label>
          <Select {...register('status')}>
            {PHONE_STATUS.map((status) => (
              <option key={status} value={status}>
                {getPhoneStatusDisplay(status)}
              </option>
            ))}
          </Select>
        </div>

        <div className="flex flex-row items-center justify-between gap-4">
          <label>Numéro</label>
          <Input {...register('number', { validate: phoneNumberValidator })} />
          {formState.errors.number && (
            <Error>{formState.errors.number.message}</Error>
          )}
        </div>

        <div className="flex flex-row items-center gap-4">
          <label>Bloquer la zone</label>
          <Input type="checkbox" className="w-5" {...register('zoneLocked')} />
        </div>

        <div className="flex flex-row items-center gap-4">
          <label>Désactiver les notifications</label>
          <Input
            type="checkbox"
            className="w-5"
            {...register('notificationDisabled')}
          />
        </div>

        <div className="flex flex-col gap-1">
          <label>Commentaire</label>
          <Textarea {...register('comment')} />
        </div>

        <div className="flex items-center justify-end gap-3">
          <Button variant="outline" onClick={handleClose}>
            Annuler
          </Button>
          <SubmitButton isSubmitting={formState.isSubmitting}>
            Valider
          </SubmitButton>
        </div>
      </form>
    </Dialog>
  )
}
