import { ErrorBoundary } from '@sentry/react'
import React from 'react'
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom'
import { Centered } from 'shared/components/Centered'
import { DataProvider } from './DataProvider'
import { Header } from './components/Header'
import { Login } from './components/Login'
import { Toaster } from './components/ui/toast'
import { useAuth } from './hooks/useAuth'
import { DeviceInfos } from './pages/explorer/DeviceInfos'
import { Explorer } from './pages/explorer/Explorer'
import { FacilityInfos } from './pages/explorer/FacilityInfos'
import { PhoneInfos } from './pages/explorer/PhoneInfos'
import { Offlines } from './pages/offlines/Offlines'
import { Orders } from './pages/orders/Orders'
import { Overview } from './pages/overview/Overview'
import { Pending } from './pages/pending/Pending'

export const App: React.FC = () => {
  const { loading, error, data: user } = useAuth()

  return (
    <React.StrictMode>
      <ErrorBoundary
        fallback={<h2>Une erreur est survenue</h2>}
        showDialog
        dialogOptions={{
          user: {
            email: user?.email ?? 'unknown',
          },
        }}
      >
        <Router>
          <div className="bg-background text-foreground flex h-screen flex-col text-base">
            {loading ? (
              <Centered>Authentification...</Centered>
            ) : error !== null ? (
              <Centered>{error.message}</Centered>
            ) : user === null ? (
              <Login />
            ) : (
              <DataProvider>
                <div className="flex min-h-0 flex-1 flex-col">
                  <Header />
                  <Routes>
                    <Route path="/overview" element={<Overview />} />
                    <Route path="/explorer" element={<Explorer />}>
                      <Route
                        path="/explorer/:facilityId/phone/:key"
                        element={<PhoneInfos />}
                      />
                      <Route
                        path="/explorer/:facilityId/:serial"
                        element={<DeviceInfos />}
                      />
                      <Route
                        path="/explorer/:facilityId"
                        element={<FacilityInfos />}
                      />
                    </Route>
                    <Route path="/offlines" element={<Offlines />} />
                    <Route path="/pending" element={<Pending />} />
                    <Route path="/orders" element={<Orders />} />
                    <Route path="*" element={<Navigate to="/explorer" />} />
                  </Routes>
                  <Toaster />
                </div>
              </DataProvider>
            )}
          </div>
        </Router>
      </ErrorBoundary>
    </React.StrictMode>
  )
}
