import { GetUserZoneIdParams, GetUserZoneIdResponseData } from 'common/httpCall'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useCallback, useState } from 'react'
import { DEFAULT_REGION } from 'shared/firebase/region'
import {
  AsyncState,
  dataState,
  errorState,
  loadingState,
} from 'shared/types/asyncState'
import { Zone } from 'shared/types/fleet'
import { FacilityId, UserId } from 'shared/types/utils'
import { app } from '../firebase'
import { get } from '../firebaseMethods'

const functions = getFunctions(app, DEFAULT_REGION)

const getUserZoneId = httpsCallable<
  GetUserZoneIdParams,
  GetUserZoneIdResponseData
>(functions, 'getUserZoneId')

export function useUserZone() {
  const [zoneState, setZoneState] = useState<
    // undefined when zone is not yet fetched, null when no zone is set
    AsyncState<Zone | undefined | null>
  >(dataState(undefined))

  const getUserZone = useCallback(
    async (facilityId: FacilityId, userId: UserId) => {
      setZoneState(loadingState())

      try {
        const resLive = await getUserZoneId({ userId })
        const zoneId = resLive.data

        if (zoneId) {
          const zone = await get(`facilities/${facilityId}/zones/${zoneId}`)
          setZoneState(dataState(zone))
        } else {
          setZoneState(dataState(null))
        }
      } catch (error) {
        console.log(error)
        setZoneState(errorState(error as Error))
      }
    },
    [],
  )

  const resetZone = useCallback(() => {
    setZoneState(dataState(undefined))
  }, [])

  return { zoneState, resetZone, getUserZone }
}
