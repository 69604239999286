import { categoryColor, categoryString } from 'shared/types/alert'
import { Region } from 'shared/types/fleet'
import { Room } from 'shared/types/utils'
import { roomsInRange } from 'shared/utils/room'
import { timeRangeString } from 'shared/utils/timeRange'
import { roomRangeString } from 'shared/utils/zone'
import { Text } from '../../components/Text'

export const ZoneRegion = ({
  rooms,
  region,
}: {
  rooms: Room[]
  region: Region
}) => {
  return (
    <div className="grid w-full grid-cols-1 sm:grid-cols-3 sm:gap-2">
      <div className="col-span-2 flex w-full flex-col sm:flex-row sm:gap-2">
        <Text
          className={`m-0.5 h-6 min-w-32 rounded-full px-2 py-1 text-white ${region.category ? categoryColor[region.category] : ''}`}
        >
          {region.category ? categoryString[region.category] : ''}
        </Text>
        {region.timeRange && <Text>{timeRangeString(region.timeRange)}</Text>}
        {region.roomRange && <Text>{roomRangeString(region.roomRange)}</Text>}
      </div>
      <div className="sm:justify-self-end">
        {roomsInRange(rooms, region.roomRange)}
      </div>
    </div>
  )
}
