import { cva, type VariantProps } from 'class-variance-authority'
import React from 'react'
import { cn } from 'shared/utils/web/cn'

export const textVariants = cva(
  'inline-flex whitespace-nowrap rounded-md text-base font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'text-foreground',
        alert: 'text-destructive',
        success: 'text-primary',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

export interface TextProps
  extends React.ButtonHTMLAttributes<HTMLDivElement>,
    VariantProps<typeof textVariants> {}

export const Text = React.forwardRef<HTMLDivElement, TextProps>(
  ({ className, variant, ...props }, ref) => {
    return (
      <div
        className={cn(textVariants({ variant, className }))}
        ref={ref}
        {...props}
      />
    )
  },
)
Text.displayName = 'Text'

export const Hint: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  className,
  ...props
}) => (
  <Text className={cn('opacity-50', className)} {...props}>
    {children}
  </Text>
)

export const Error: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, className }) => (
  <Text className={cn('text-destructive', className)}>{children}</Text>
)

export const Title: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, className }) => (
  <Text className={cn('text-xl font-bold', className)}>{children}</Text>
)
