import { Timestamp } from 'common/types'
import React from 'react'

type TimelineSlot = {
  isOffline: boolean
  relativeDuration: number
}

export type Timeline = Record<Timestamp, TimelineSlot>

interface TimelineProps {
  deviceTimeline: Timeline
  deviceIsOffline: boolean
}

export const OfflineGraph: React.FC<TimelineProps> = ({
  deviceTimeline,
  deviceIsOffline,
}) => {
  const relativeDurationsSum = Object.values(deviceTimeline ?? {}).reduce(
    (acc, currentValue) => acc + (currentValue.relativeDuration ?? 0),
    0,
  )

  const firstTimestampHistory = Object.values(deviceTimeline ?? {})[0]

  return (
    <div className="flex h-full w-full">
      <div
        className={`${
          firstTimestampHistory
            ? firstTimestampHistory.isOffline
              ? 'bg-green-800'
              : 'bg-orange-500'
            : deviceIsOffline
              ? 'bg-orange-500'
              : 'bg-green-800'
        }`}
        style={{
          width: `${100 - relativeDurationsSum}%`,
        }}
      ></div>

      {Object.entries(deviceTimeline ?? {}).map(
        ([timestamp, { isOffline, relativeDuration }]) => {
          return (
            <div
              key={timestamp}
              className={`${isOffline ? 'bg-orange-500' : 'bg-green-800'}`}
              style={{
                width: `${relativeDuration}%`,
              }}
            ></div>
          )
        },
      )}
    </div>
  )
}
