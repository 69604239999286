import { captureException } from '@sentry/react'
import { isFacilityTypeCustomerOrExperimenter } from 'common/facilityType'
import { phoneNumberValidator } from 'common/phoneNumber'
import { RegisteredPhone } from 'common/types'
import { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Facilities } from 'shared/types/fleet'
import { Deferred } from 'shared/utils/web/deferred'
import { DataContext } from '../../DataProvider'
import { Dialog, DIALOG_CLOSED_REASON } from '../../components/Dialog'
import { FacilitySelector } from '../../components/FacilitySelector'
import { Select } from '../../components/Select'
import { Error, Title } from '../../components/Text'
import { Button, SubmitButton } from '../../components/ui/button'
import { Input } from '../../components/ui/input'
import { Textarea } from '../../components/ui/textarea'
import { update } from '../../firebaseMethods'
import { getPhoneStatusDisplay, PHONE_STATUS } from '../../utils/phoneStatus'

const STORAGE_KEY = 'lastAddPhoneValues'

export interface AddPhoneDialogData {
  model: string
  deferred: Deferred<void>
}

export function onlyCustomerOrExperimenter(facilities: Facilities) {
  return Object.entries(facilities).reduce<Facilities>(
    (acc, [facilityId, facility]) => {
      if (isFacilityTypeCustomerOrExperimenter(facility.type))
        acc[facilityId] = facility
      return acc
    },
    {},
  )
}

export const AddPhoneDialog = ({ model, deferred }: AddPhoneDialogData) => {
  const { facilities, source } = useContext(DataContext)

  const { handleSubmit, register, formState, setValue } =
    useForm<RegisteredPhone>({
      defaultValues: {
        model,
        facilityId: Object.keys(facilities)[0],
        number: '',
        status: 'active',
        comment: '',
        source,
      },
      mode: 'onBlur',
    })

  useEffect(() => {
    const data = localStorage.getItem(STORAGE_KEY)
    if (data !== null) {
      try {
        const { facilityId } = JSON.parse(data)
        setValue('facilityId', facilityId)
      } catch (error) {
        captureException(error, { extra: { data } })
        console.info(
          `Could not parse and initialize from localStorage '${STORAGE_KEY}'`,
          data,
          error,
        )
        localStorage.removeItem(STORAGE_KEY)
      }
    }
  }, [setValue])

  const handleClose = () => {
    if (!formState.isSubmitting) {
      deferred.reject(DIALOG_CLOSED_REASON)
    }
  }

  return (
    <Dialog title={<Title>Ajouter un ARI Phone</Title>} onClose={handleClose}>
      <form
        className="flex flex-col gap-6"
        onSubmit={handleSubmit(async (values) => {
          localStorage.setItem(STORAGE_KEY, JSON.stringify(values))
          await update('pendingPhone', values)
          deferred.resolve()
        })}
      >
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <label>Modèle</label>
            <Input {...register('model', { required: 'Modèle requis' })} />
            {formState.errors.model && (
              <Error>{formState.errors.model.message}</Error>
            )}
          </div>

          <div className="flex flex-col gap-2">
            <label>Établissement</label>
            <FacilitySelector
              facilities={onlyCustomerOrExperimenter(facilities)}
              registration={register('facilityId', {
                required: 'Établissement requis',
              })}
            />
            {formState.errors.facilityId && (
              <Error>{formState.errors.facilityId.message}</Error>
            )}
          </div>

          <div className="flex flex-col gap-2">
            <label>Status</label>
            <Select {...register('status')}>
              {PHONE_STATUS.map((status) => (
                <option key={status} value={status}>
                  {getPhoneStatusDisplay(status)}
                </option>
              ))}
            </Select>
            {formState.errors.status && (
              <Error>{formState.errors.status.message}</Error>
            )}
          </div>

          <div className="flex flex-col gap-2">
            <label>Numéro SIM (06 / 07 .. .. .. ..)</label>
            <Input
              {...register('number', { validate: phoneNumberValidator })}
            />
            {formState.errors.number && (
              <Error>{formState.errors.number.message}</Error>
            )}
          </div>

          <div className="flex flex-col gap-2">
            <label>Commentaire</label>
            <Textarea {...register('comment')} />
          </div>
        </div>

        <div className="flex items-center justify-end gap-3">
          <Button variant="outline" onClick={handleClose}>
            Annuler
          </Button>
          <SubmitButton isSubmitting={formState.isSubmitting}>
            Valider
          </SubmitButton>
        </div>
      </form>
    </Dialog>
  )
}
