import { DatabaseSchema } from 'common/databaseSchema'
import { Phone } from 'common/types'
import React, { createContext } from 'react'
import { Centered } from 'shared/components/Centered'
import { MergedType, Nullable } from 'shared/hooks/createUseMergedFirebase'
import { FacilityId, FirebaseKey } from 'shared/types/utils'
import { auth } from './firebase'
import { useMergedFirebase } from './hooks/useMergedFirebase'

// Defined outside of component to get a constant reference
const refPathsMap = {
  facilities: 'facilities' as const,
  facilityDevices: 'facilityDevices_' as const,
  phones: 'phones' as const,
  orders: 'orders' as const,
  ordersUsage: 'ordersUsage_' as const,
  grafana: 'connections/grafana' as const,
  salt: 'connections/salt' as const,
}

type DatabaseData = MergedType<typeof refPathsMap, DatabaseSchema>

export type FacilityPhones = Record<FacilityId, Record<FirebaseKey, Phone>>

type Context = DatabaseData & {
  source: string
  facilityPhones: FacilityPhones
}

const defaultDataContextValue: Context = {
  facilities: {},
  facilityDevices: {},
  phones: {},
  grafana: {
    url: '',
    tokens: { read: '', write: '' },
  },
  salt: {
    url: '',
    credentials: { username: '', password: '' },
  },
  orders: {},
  ordersUsage: {},
  source: 'unknown',
  facilityPhones: {},
}

export const DataContext = createContext<Context>(defaultDataContextValue)

function dataTransform(data: Nullable<DatabaseData>) {
  const grafana = data.grafana ?? defaultDataContextValue.grafana
  const salt = data.salt ?? defaultDataContextValue.salt
  const facilities = data.facilities ?? defaultDataContextValue.facilities
  const facilityDevices =
    data.facilityDevices ?? defaultDataContextValue.facilityDevices
  const phones = data.phones ?? defaultDataContextValue.phones
  const orders = data.orders ?? defaultDataContextValue.orders
  const ordersUsage = data.ordersUsage ?? defaultDataContextValue.ordersUsage

  const facilityPhones: FacilityPhones = {}

  for (const facilityId in facilities) {
    facilityPhones[facilityId] = {}
  }

  for (const [key, phone] of Object.entries(phones)) {
    facilityPhones[phone.facilityId][key] = phone
  }

  const transformedData: Context = {
    facilities,
    facilityDevices,
    phones,
    orders,
    ordersUsage,
    grafana,
    salt,
    source: auth.currentUser?.email ?? 'unknown',
    facilityPhones,
  }

  return transformedData
}

export const DataProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const state = useMergedFirebase(refPathsMap, dataTransform)

  if (state.error) {
    return <Centered>Erreur lors du chargement des données...</Centered>
  }

  if (state.loading) {
    return <Centered>Chargement...</Centered>
  }

  return (
    <DataContext.Provider value={state.data}>{children}</DataContext.Provider>
  )
}
