import { DatabaseSchema } from 'common/databaseSchema'
import { RegisterVpnClientRequestData } from 'common/httpCall'
import { DeviceEdition, Edit } from 'common/types'
import { getFunctions, httpsCallable } from 'firebase/functions'
import {
  ChevronRight,
  Clock,
  Network,
  Pencil,
  Terminal,
  Wifi,
  Wrench,
} from 'lucide-react'
import { DateTime } from 'luxon'
import React, { useContext, useMemo, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { Centered } from 'shared/components/Centered'
import { DEFAULT_REGION } from 'shared/firebase/region'
import { MergedType, Nullable } from 'shared/hooks/createUseMergedFirebase'
import { GetPushKey } from 'shared/types/firebase'
import { Device, RoomConfig, TimeRanges, Wifis } from 'shared/types/fleet'
import { Serial } from 'shared/types/utils'
import { plural } from 'shared/utils/plural'
import { timeRangeString } from 'shared/utils/timeRange'
import { Deferred } from 'shared/utils/web/deferred'
import { DataContext } from '../../DataProvider'
import { editDevice } from '../../api'
import { DeviceListItem } from '../../components/DeviceListItem'
import { DIALOG_CLOSED_REASON } from '../../components/Dialog'
import { GrafanaPanel } from '../../components/GrafanaPanel'
import { LabelValueDisplay } from '../../components/NetworkInfo'
import { Title } from '../../components/Text'
import { Button } from '../../components/ui/button'
import { app } from '../../firebase'
import { getPushKey } from '../../firebaseMethods'
import { useMergedFirebase } from '../../hooks/useMergedFirebase'
import { usePing } from '../../hooks/usePing'
import RoomExitIcon from '../../icons/room-exit.svg?react'
import { Edited } from '../../utils/computeDiff'
import {
  DeviceResetDialog,
  DeviceResetDialogProps,
} from './ConfirmDeviceResetDialog'
import {
  ExpireVpnCacheDialog,
  ExpireVpnCacheDialogProps,
} from './ConfirmExpireVpnCacheDialog'
import { DeviceHistory } from './DeviceHistory'
import {
  DeviceOptionsDialog,
  DeviceOptionsDialogProps,
} from './DeviceOptionsDialog'
import { DevicePieces } from './DevicePieces'
import { EditDeviceDialog, EditDeviceDialogData } from './EditDeviceDialog'
import { RepairDeviceDialog } from './RepairDeviceDialog'
import {
  DeviceTimeRangesDialog,
  DeviceTimeRangesDialogProps,
} from './TimeRangesDialog'
import { WifisDialog } from './WifisDialog'

export function getLastDatetime(timestamps: number[]) {
  return timestamps.length ? DateTime.fromMillis(Math.max(...timestamps)) : null
}

export const DeviceInfos: React.FC = () => {
  const { serial } = useParams() as { serial: string }

  const [editDialogData, setEditDialogData] =
    useState<EditDeviceDialogData | null>(null)

  const [repairDialogData, setRepairDialogData] =
    useState<EditDeviceDialogData | null>(null)

  const [DeviceResetDialogData, setDeviceResetDialogData] =
    useState<DeviceResetDialogProps | null>(null)

  const [timeRangesDialogData, setTimeRangesDialogData] =
    useState<DeviceTimeRangesDialogProps | null>(null)

  const [optionsDialogData, setOptionsDialogData] =
    useState<DeviceOptionsDialogProps | null>(null)

  const [expireVpnCacheDialogData, setExpireVpnCacheDialogData] =
    useState<ExpireVpnCacheDialogProps | null>(null)

  const [wifisDialogData, setWifisDialogData] = useState<{
    deferred: Deferred<Nullable<Wifis>>
    wifis: Wifis
    getPushKey: GetPushKey
  } | null>(null)

  const { pingStatuses, handlePing } = usePing()

  const navigate = useNavigate()

  const { source, facilities } = useContext(DataContext)

  const refPathsMap = useMemo(
    () => ({
      device: `devices/${serial}` as const,
      version: `devicesVersion/${serial}` as const,
      ping: `devicesPing/${serial}` as const,
      history: `history/devices/${serial}` as const,
    }),
    [serial],
  )

  const { data, loading, error } =
    useMergedFirebase<MergedType<typeof refPathsMap, DatabaseSchema>>(
      refPathsMap,
    )

  const sortedHistoryEntries = useMemo(() => {
    if (loading || error) return []

    return Object.entries(data.history).sort(
      ([, { timestamp: timestampA }], [, { timestamp: timestampB }]) =>
        timestampB - timestampA,
    )
  }, [data, loading, error])

  async function handleEdit(
    device: Device,
    deviceStates: [string, Edit<Device>][],
  ) {
    const deferred = new Deferred<Edited<DeviceEdition>>()

    const timestamps = deviceStates.map(([, { timestamp }]) => timestamp)

    setEditDialogData({
      device,
      deferred,
      serial,
      minDatetime: getLastDatetime(timestamps),
    })

    try {
      const diff = await deferred.promise
      if (diff?.facilityId) {
        navigate(`/explorer/${diff.facilityId}/${serial}`)
      }
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setEditDialogData(null)
    }
  }

  const handleDeviceReset = async (device: Device) => {
    const deferred = new Deferred<Edited<DeviceEdition>>()

    setDeviceResetDialogData({
      deferred,
      device,
      serial,
    })

    try {
      await deferred.promise
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setDeviceResetDialogData(null)
    }
  }

  const handleExpireVpnCache = async (device: Device) => {
    const deferred = new Deferred<Edited<DeviceEdition>>()

    setExpireVpnCacheDialogData({
      deferred,
      device,
      serial,
    })

    try {
      await deferred.promise
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setExpireVpnCacheDialogData(null)
    }
  }

  const handleRepair = async (
    device: Device,
    deviceStates: [string, Edit<Device>][],
  ) => {
    const deferred = new Deferred<Edited<DeviceEdition>>()

    const timestamps = deviceStates.map(([, { timestamp }]) => timestamp)

    setRepairDialogData({
      device,
      deferred,
      minDatetime: getLastDatetime(timestamps),
      serial,
    })

    try {
      await deferred.promise
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setRepairDialogData(null)
    }
  }

  if (loading) return <Centered>Chargement...</Centered>
  if (error) return <Centered>Erreur</Centered>
  if (!data.device) return <Centered>Appareil inconnu</Centered>

  const device = data.device

  const {
    facilityId,
    recordingTimeRange,
    analysisTimeRange,
    monitoringTimeRange,
    roomExitTimeRange,
    wifis = {},
  } = device

  const {
    displayName,
    recordingTimeRange: facilityRecordingTimeRange,
    analysisTimeRange: facilityAnalysisTimeRange,
    monitoringTimeRange: facilityMonitoringTimeRange,
  } = facilities[facilityId]

  const facilityTimeRanges: TimeRanges = {
    recordingTimeRange: facilityRecordingTimeRange,
    analysisTimeRange: facilityAnalysisTimeRange,
    monitoringTimeRange: facilityMonitoringTimeRange,
  }

  const options = {
    roomExitTimeRange,
  }

  const nbOptions = Object.values(options).filter(
    (option) => option !== undefined,
  ).length

  const timeRanges = {
    monitoringTimeRange,
    recordingTimeRange,
    analysisTimeRange,
  }

  const nbTimeRanges = Object.values(timeRanges).filter(
    (timeRange) => timeRange !== undefined,
  ).length

  const handleEditTimeRanges = async () => {
    const deferred = new Deferred<Nullable<TimeRanges>>()

    const timeRanges = {
      recordingTimeRange,
      analysisTimeRange,
      monitoringTimeRange,
    }

    setTimeRangesDialogData({ deferred, timeRanges, facilityTimeRanges })

    try {
      const timeRanges = await deferred.promise
      await editDevice(serial, device, timeRanges, source, Date.now())
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setTimeRangesDialogData(null)
    }
  }

  const handleSSHConnect = async () => {
    const url = `http://ssh.webssh.svc.cluster.local/ssh/host/${device.vpnIpAddress}`
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  const handleRegisterVpnClient = async () => {
    await registerVpnClients([serial])
  }

  const handleShowOptions = async () => {
    const deferred = new Deferred<Nullable<RoomConfig>>()

    const options: Nullable<RoomConfig> = {
      roomExitTimeRange: roomExitTimeRange ?? null,
    }

    setOptionsDialogData({
      deferred,
      options,
    })

    try {
      const options = await deferred.promise
      await editDevice(serial, device, options, source, Date.now())
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setOptionsDialogData(null)
    }
  }

  const handleShowWifis = async () => {
    const deferred = new Deferred<Nullable<Wifis>>()

    const getDeviceWifiPushKey = () => getPushKey(`devices/${facilityId}/wifis`)

    setWifisDialogData({ deferred, wifis, getPushKey: getDeviceWifiPushKey })

    try {
      const wifis = await deferred.promise
      await editDevice(serial, device, { wifis }, source, Date.now())
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setWifisDialogData(null)
    }
  }

  if (loading) return <div>Chargement</div>
  if (error) return <div>Erreur</div>

  return (
    <div className="flex-1 overflow-auto">
      {editDialogData && <EditDeviceDialog {...editDialogData} />}

      {optionsDialogData && <DeviceOptionsDialog {...optionsDialogData} />}

      {wifisDialogData && <WifisDialog {...wifisDialogData} />}

      {timeRangesDialogData && (
        <DeviceTimeRangesDialog {...timeRangesDialogData} />
      )}

      {repairDialogData && <RepairDeviceDialog {...repairDialogData} />}

      {DeviceResetDialogData && (
        <DeviceResetDialog {...DeviceResetDialogData} />
      )}

      {expireVpnCacheDialogData && (
        <ExpireVpnCacheDialog {...expireVpnCacheDialogData} />
      )}

      <div className="flex flex-col gap-4 p-4">
        <div className="flex flex-wrap justify-between gap-2">
          <div className="flex gap-4">
            <div className="flex flex-1 flex-wrap items-center gap-2">
              <Title>
                <NavLink to={`/explorer/${facilityId}`}>{displayName}</NavLink>
              </Title>
              <ChevronRight />
              {device.roomExitTimeRange && (
                <div title="Détection 'sorties de chambre' actif">
                  <RoomExitIcon className="w-8 fill-current" />
                </div>
              )}
              <DeviceListItem device={device} serial={serial} />
              <span>
                {data.ping &&
                  DateTime.fromMillis(data.ping).setLocale('fr').toRelative()}
                &nbsp;v{data.version ?? '1'}
              </span>
            </div>
          </div>
          <div className="flex gap-2">
            <Button
              onClick={handleShowWifis}
              title="paramétrer les réseaux Wifis"
            >
              <div className="flex items-center gap-2">
                <Wifi />
                <span>{Object.keys(wifis ?? []).length}</span>
              </div>
            </Button>
            <Button
              onClick={handleEditTimeRanges}
              title="paramétrer les plages horaires"
            >
              <div className="flex items-center gap-2">
                <Clock />
                <span>{nbTimeRanges}</span>
              </div>
            </Button>
            <Button
              onClick={handleShowOptions}
              title="paramétrer les sorties de chambre"
            >
              <div className="flex items-center gap-2">
                <RoomExitIcon className="w-8 fill-current" />
                <span>{nbOptions}</span>
              </div>
            </Button>
            <Button
              onClick={() => handleEdit(device, sortedHistoryEntries)}
              title="paramétrer le boitier"
            >
              <Pencil />
            </Button>
          </div>
        </div>
        <div className="flex gap-2">
          <Button
            onClick={() => handleRepair(device, sortedHistoryEntries)}
            title="remplacer pièce défectueuse"
          >
            <Wrench />
          </Button>
          <Button
            onClick={() => handlePing(serial)}
            disabled={pingStatuses[serial] === 'progress'}
            title="Ping boitier"
          >
            Ping
          </Button>
          <Button
            onClick={handleRegisterVpnClient}
            title="Enregistrer le boitier sur le VPN"
          >
            <div className="flex items-center gap-2">
              <Network />
              <span>{device.vpnClientConfig ? 1 : 0}</span>
            </div>
          </Button>
          {device.secretId && (
            <Button
              onClick={() => handleDeviceReset(device)}
              title="Réinitialiser le boitier"
            >
              <div className="flex items-center gap-2">Reset boitier</div>
            </Button>
          )}
          {device.vpnClientConfig && (
            <Button
              onClick={() => handleExpireVpnCache(device)}
              title="Faire expirer le cache VPN"
            >
              <div className="flex items-center gap-2">Expirer cache VPN</div>
            </Button>
          )}
          <Button
            onClick={handleSSHConnect}
            title="Ouvrir un terminal sur le boitier (Nécessite d'être connecté au VPN)"
          >
            <Terminal />
          </Button>
        </div>
        <div className="flex flex-col gap-6 xl:flex-row xl:justify-between">
          <DevicePieces
            pieceIds={{ ...device.partsIds, COMPUTER: device.orderId }}
          />
          <div className="flex flex-col gap-2">
            <LabelValueDisplay label="IP ADDRESS" value={device.ipAddress} />
            <LabelValueDisplay
              label="VPN IP ADDRESS"
              value={device.vpnIpAddress}
            />
            <LabelValueDisplay label="MAC ADDRESS" value={device.macAddress} />
            <LabelValueDisplay label="SSID" value={device.ssid} />
            <LabelValueDisplay
              label="ACCESS POINT MAC ADDRESS"
              value={device.accessPointMacAddress}
            />
          </div>
          <div className="flex flex-col items-start gap-2 xl:items-end">
            {roomExitTimeRange && (
              <LabelValueDisplay
                label="Sortie de chambre"
                value={timeRangeString(roomExitTimeRange)}
              />
            )}
            {recordingTimeRange && (
              <LabelValueDisplay
                label="Enregistrement"
                value={timeRangeString(recordingTimeRange)}
              />
            )}
            {analysisTimeRange && (
              <LabelValueDisplay
                label="Analyse"
                value={timeRangeString(analysisTimeRange)}
              />
            )}
            {monitoringTimeRange && (
              <LabelValueDisplay
                label="Surveillance"
                value={timeRangeString(monitoringTimeRange)}
              />
            )}
          </div>
        </div>
        <div className="flex h-auto flex-col gap-2 lg:h-48 lg:flex-row">
          <GrafanaPanel
            dashboardId="ari-v2"
            panelId={62021}
            facility={displayName}
            serial={serial}
          />
          <GrafanaPanel
            dashboardId="ari-v2"
            panelId={42026}
            facility={displayName}
            serial={serial}
          />
          <GrafanaPanel
            dashboardId="ari-v2"
            panelId={62007}
            facility={displayName}
            serial={serial}
          />
        </div>
        <DeviceHistory history={data.history} />
      </div>
    </div>
  )
}

const functions = getFunctions(app, DEFAULT_REGION)
const registerVpnClient = httpsCallable<RegisterVpnClientRequestData>(
  functions,
  'registerVpnClient',
)

export async function registerVpnClients(serials: Serial[]) {
  await registerVpnClient({ serials })
    .then(() => {
      alert(
        `🎉 Enregistrement VPN de ${plural(serials, 'boîtier', true)} réussi !`,
      )
    })
    .catch((error) => {
      console.error(error)
      alert(error)
    })
}
