export const defaultPolicy = {
  applications: [
    {
      packageName: 'com.oso.live.prod',
      installType: 'KIOSK',
      defaultPermissionPolicy: 'GRANT',
      autoUpdateMode: 'AUTO_UPDATE_HIGH_PRIORITY',
    },
    {
      packageName: 'com.google.android.webview',
      installType: 'FORCE_INSTALLED',
      defaultPermissionPolicy: 'GRANT',
      autoUpdateMode: 'AUTO_UPDATE_HIGH_PRIORITY',
    },
    {
      packageName: 'com.google.android.dialer',
      installType: 'FORCE_INSTALLED',
      defaultPermissionPolicy: 'GRANT',
      autoUpdateMode: 'AUTO_UPDATE_HIGH_PRIORITY',
    },
    {
      packageName: 'com.android.emergency',
      installType: 'FORCE_INSTALLED',
      defaultPermissionPolicy: 'GRANT',
      autoUpdateMode: 'AUTO_UPDATE_HIGH_PRIORITY',
    },
    {
      packageName: 'com.google.android.inputmethod.latin',
      installType: 'FORCE_INSTALLED',
      defaultPermissionPolicy: 'GRANT',
      autoUpdateMode: 'AUTO_UPDATE_HIGH_PRIORITY',
    },
  ],
  openNetworkConfiguration: {
    NetworkConfigurations: [
      {
        GUID: 'OSO',
        Name: 'OSO',
        Type: 'WiFi' as const,
        WiFi: {
          SSID: 'OSO',
          Security: 'WPA-PSK' as const,
          Passphrase: 'OSOBrest',
          HiddenSSID: false,
          MACAddressRandomizationMode: 'Hardware' as const,
          AutoConnect: true,
        },
      },
    ],
  },
  deviceConnectivityManagement: {
    wifiRoamingPolicy: {
      wifiRoamingSettings: [],
    },
  },
  systemUpdate: { type: 'AUTOMATIC' as const },
  mountPhysicalMediaDisabled: true,
  keyguardDisabled: true,
  deviceOwnerLockScreenInfo: {
    defaultMessage: 'OSO Phone',
  },
  networkEscapeHatchEnabled: true,
  skipFirstUseHintsEnabled: true,
  kioskCustomization: {
    powerButtonActions: 'POWER_BUTTON_AVAILABLE' as const,
    systemErrorWarnings: 'ERROR_AND_WARNINGS_ENABLED' as const,
    systemNavigation: 'NAVIGATION_ENABLED' as const,
    statusBar: 'NOTIFICATIONS_AND_SYSTEM_INFO_ENABLED' as const,
    deviceSettings: 'SETTINGS_ACCESS_BLOCKED' as const,
  },
}
