import { Wifi, Wifis } from 'shared/types/fleet'
import { fetch_ } from 'shared/utils/fetch'
import { defaultPolicy } from './defaultPolicy'
import {
  Device,
  EnrollmentToken,
  EnrollmentTokenResponse,
  NetworkConfiguration,
  Policy,
  TokenResponse,
} from './mdmTypes'

export async function getAccessToken(clientId: string) {
  return new Promise<string>((resolve, reject) => {
    const tokenClient = window.google.accounts.oauth2.initTokenClient({
      client_id: clientId,
      scope: 'https://www.googleapis.com/auth/androidmanagement',
      ux_mode: 'popup',
      callback: (tokenResponse: TokenResponse) => {
        resolve(tokenResponse.access_token)
      },
      error_callback: (error: unknown) => {
        console.error('Error when requesting token', error)
        reject(error)
      },
    })

    tokenClient.requestAccessToken()
  })
}

const enterpriseId = 'LC01vj6q40'
const serviceEndPoint = 'https://androidmanagement.googleapis.com'

export async function listPolicies(token: string) {
  const endPoint = `${serviceEndPoint}/v1/enterprises/${enterpriseId}/policies`

  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  return await fetch_(endPoint, options).then((response) => response.json())
}

function asNetworkConfiguration(wifi: Wifi): NetworkConfiguration {
  return {
    GUID: wifi.ssid,
    Name: wifi.ssid,
    Type: 'WiFi' as const,
    WiFi: {
      SSID: wifi.ssid,
      Security: 'WPA-PSK' as const,
      Passphrase: wifi.password,
      HiddenSSID: wifi.isHidden,
      MACAddressRandomizationMode: 'Hardware' as const,
      AutoConnect: true,
    },
  }
}

function wifiRoamingPolicy(
  facilityOpenNetworkConfiguration: NetworkConfiguration[],
) {
  return {
    wifiRoamingSettings: facilityOpenNetworkConfiguration.map((config) => ({
      wifiSsid: config.WiFi.SSID,
      wifiRoamingMode: 'WIFI_ROAMING_AGGRESSIVE' as const,
    })),
  }
}

export async function syncPolicy(
  token: string,
  policyId: string,
  wifis: Wifis,
) {
  const policy: Policy = { ...defaultPolicy }

  const facilityOpenNetworkConfiguration = Object.values(wifis)
    .sort(
      ({ priority: priorityA }, { priority: priorityB }) =>
        priorityA - priorityB,
    )
    .map(asNetworkConfiguration)

  policy.openNetworkConfiguration.NetworkConfigurations = [
    ...facilityOpenNetworkConfiguration,
    ...policy.openNetworkConfiguration.NetworkConfigurations,
  ]

  policy.deviceConnectivityManagement.wifiRoamingPolicy = wifiRoamingPolicy(
    facilityOpenNetworkConfiguration,
  )

  const endPoint = `${serviceEndPoint}/v1/enterprises/${enterpriseId}/policies/${policyId}`

  const options = {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(policy),
  }

  return await fetch_(endPoint, options).then((response) => response.json())
}

export async function createEnrollmentToken(token: string, policyId: string) {
  const enrollmentToken: EnrollmentToken = {
    duration: '10000000s',
    policyName: `enterprises/${enterpriseId}/policies/${policyId}`,
    additionalData: policyId,
    oneTimeOnly: false,
    allowPersonalUsage: 'PERSONAL_USAGE_DISALLOWED',
  }

  const endPoint = `${serviceEndPoint}/v1/enterprises/${enterpriseId}/enrollmentTokens`

  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(enrollmentToken),
  }

  return await fetch_(endPoint, options)
    .then((response) => response.json())
    .then((result) => result as EnrollmentTokenResponse)
}

export async function getDevice(token: string, deviceId: string) {
  const endPoint = `${serviceEndPoint}/v1/enterprises/${enterpriseId}/devices/${deviceId}`

  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  return await fetch_(endPoint, options)
    .then((response) => response.json())
    .then((result) => result as Device)
}

export async function rebootDevice(token: string, deviceId: string) {
  const command = { type: 'REBOOT' }

  const endPoint = `${serviceEndPoint}/v1/enterprises/${enterpriseId}/devices/${deviceId}`

  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(command),
  }

  return await fetch_(endPoint, options).then((response) => response.json())
}
